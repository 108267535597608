import { Bloom, EffectComposer, FXAA, LUT, SSR } from "@react-three/postprocessing";
import { useLoader } from "@react-three/fiber";
import { LUTCubeLoader } from "postprocessing";
import { useControls } from "leva";
import { Texture } from "three";

/* //   <Bloom luminanceThreshold={0.1} mipmapBlur luminanceSmoothing={0} intensity={0.5} /> */
/* <LUT lut={texture as Texture} /> */

const Effects = () => {
  const texture = useLoader(LUTCubeLoader, "/F-6800-STD.cube");

  return (
    <EffectComposer disableNormalPass>
      <FXAA />
      <Bloom luminanceThreshold={0.5} mipmapBlur luminanceSmoothing={0.1} intensity={0.5} /> 
      <LUT lut={texture as Texture} />
    </EffectComposer>
  );
};

export default Effects;
