import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { useGLTF } from "@react-three/drei";
import { Material, Mesh, MeshStandardMaterial } from "three";
import { useMemo } from "react";
import { applyProps } from "@react-three/fiber";
import AppConfig from '../AppConfig';
import { useModelDecorator } from '../hooks/useModelDecorator';

interface GLTFU extends GLTF {
  nodes: Record<string, Mesh>;
  materials: Record<string, Material>;
}

const Bmw = (props: any) => {
  const { scene, nodes } = useGLTF(AppConfig.mainModelURL) as GLTFU;

  useModelDecorator({ config: AppConfig.decorations, object: scene })

  return <primitive object={scene} {...props} />;
};

export default Bmw;
