import { Stats } from "@react-three/drei";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Leva } from "leva";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Suspense>
      <App />
      <Leva />
    </Suspense>
  </React.StrictMode>
);
