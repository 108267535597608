import { IConfig } from './types'

const AppConfig: IConfig = {
  decorations: [
    {
      mesh: 'Object_66',
      material_properties: {
        color: 0xffffff,
        
      },
      textures: [
        {
          type: 'map',
          url: null,
        }
      ]
    },
    
    {
      mesh: 'Object_46',
      textures: [
        {
          type: 'map',
          url: null,
        }
      ],
      material_properties: {
        color: 'black',
      },
    },
    {
      mesh: 'Object_143',
      material_properties: {
        color: 0xff0000,
        
      },
    },
    {
      mesh: 'Object_86',
      material_properties: {
        color: 0xffffff,
        
      },
      textures: [
        {
          type: 'map',
          url: null,
        }
      ]
    },
    {
      mesh: 'Object_165',
      material_properties: {
        color: 'grey',
        
      },
      textures: [
        {
          type: 'map',
          url: null,
        }
      ]
    },
    {
      mesh: ['Object_160'],
      mesh_properties: { 
        visible: false, 
      }
    },
    {
      mesh: ['Object_158'],
      material_properties: { 
        emissiveIntensity: 20,  
        toneMapped: false
      }
    },
    {
      mesh: ['Object_162'],
      material_properties: { 
        emissiveIntensity: 20,  
        color: 0x000000,
        emissive: 0Xff1f5e,
        toneMapped: false
      }
    },
    {
      mesh: ['Object_163'],
      material_properties: { 
        emissiveIntensity: 20,  
        color: 0x000000,
        emissive: 'yellow',
        toneMapped: false
      }
    },
    {
      mesh: ['Object_161'],
      material_properties: { 
        emissiveIntensity: 20,  
        color: 0x000000,
        emissive: 'red',
        toneMapped: false
      }
    },
    {
      mesh: ['Object_174'],
      mesh_properties: {
        visible: false
      }
    },

  ],
  mainModelURL: './assets/supra.glb'
}
export default AppConfig;